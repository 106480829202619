<template>
  <div class="FBAOrder">
      <div class="fbaOrderInner">
          <div class="fbaOrderTit">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/quotedPrice' }">报价</el-breadcrumb-item>
                <el-breadcrumb-item>填写信息</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="fbaOrderMain">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="fabOrderItem">
                        <div class="fabItemTit">
                            地址信息
                        </div>
                        <div class="fabItemMain">
                            <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm" size="small">
                                <el-form-item label="收货人信息" prop="resource">
                                     <el-radio-group v-model="ruleForm.resource">
                                        <el-radio label="FBA"></el-radio>
                                        <el-radio label="非FBA"></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-row>
                                 <el-col :span="12">
                                     <el-form-item label="收货人国家" prop="resource">
                                        <el-select  v-model="ruleForm.resource" placeholder="请选择收货人国家">
                                            <el-option label="美国" value="0"></el-option>
                                        </el-select>
                                    </el-form-item>
                                 </el-col>
                                 <el-col :span="12">
                                     <el-form-item label="FBA仓库编码" prop="resource">
                                        <el-select placeholder="请选择仓库编码" v-model="ruleForm.resource">
                                            <el-option label="BOS5(02072)" value="1"></el-option>
                                            <el-option label="BOS5(02072)" value="2"></el-option>
                                            <el-option label="BOS5(02072)" value="3"></el-option>
                                            <el-option label="BOS5(02072)" value="4"></el-option>
                                            <el-option label="BOS5(02072)" value="5"></el-option>
                                        </el-select>
                                    </el-form-item>
                                 </el-col>
                                 </el-row>
                                <el-form-item label="发货人信息" prop="resource">
                                     <el-radio-group  v-model="ruleForm.resource">
                                        <el-radio label="上门取件"></el-radio>
                                        <el-radio label="送货到仓"></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="上门取件时间">
                                    <el-time-picker placeholder="选择时间" v-model="ruleForm.date2" style="width: 100%;"></el-time-picker>
                                </el-form-item>
                                <el-row>
                                <el-col :span="12">
                                    <el-form-item label="联系人" prop="name">
                                        <el-input v-model="ruleForm.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="联系电话" prop="name">
                                        <el-input v-model="ruleForm.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                </el-row>
                                <el-row>
                                <el-col :span="12">
                                    <el-form-item label="取件地址" prop="name">
                                        <el-cascader :options="options" clearable></el-cascader>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="详细地址" prop="name">
                                        <el-input v-model="ruleForm.name"></el-input>
                                    </el-form-item>
                                </el-col>
                                </el-row>
                            </el-form>
                        </div>
                    </div>
                    <div class="fabOrderItem">
                        <div class="fabItemTit">
                            货物信息
                        </div>
                        <div class="fabItemMain">
                             <el-button type="primary">表格上传</el-button>
                             <el-divider></el-divider>
                             <div class="boxItem">
                                 <el-form :inline="true" :model="formInline" class="demo-form-inline" label-position="top" size="small">
                                    <el-form-item label="FBA外箱标">
                                        <el-input v-model="formInline.user"></el-input>
                                    </el-form-item>
                                    <el-form-item label="货件追踪编号">
                                        <el-input v-model="formInline.user"></el-input>
                                    </el-form-item>
                                    <el-form-item label="毛重">
                                        <el-input v-model="formInline.user"></el-input>
                                    </el-form-item>
                                    <el-form-item label="长">
                                        <el-input v-model="formInline.user"></el-input>
                                    </el-form-item>
                                    <el-form-item label="宽">
                                        <el-input v-model="formInline.user"></el-input>
                                    </el-form-item>
                                    <el-form-item label="高">
                                        <el-input v-model="formInline.user"></el-input>
                                    </el-form-item>
                                </el-form>
                             </div>
                            <el-divider></el-divider>
                            <el-button type="success">添加</el-button>
                        </div>
                    </div>
                    <div class="fabOrderItem">
                        <div class="fabItemTit">
                            贸易信息
                        </div>
                        <div class="fabItemMain">
                            <el-radio v-model="radio" label="1">非一般贸易</el-radio>
                            <el-radio v-model="radio" label="2">一般贸易（可出口退税）</el-radio>
                        </div>
                    </div>
                    <div class="fabOrderItem">
                        <div class="fabItemTit">
                            补充信息
                        </div>
                        <div class="fabItemMain">
                             <el-row :gutter="20">
                                <el-col :span="12">
                                    <label for="">
                                        自有订单
                                        <el-input v-model="input" placeholder="请输入内容" size="small">></el-input>
                                    </label>
                                </el-col>
                                 <el-col :span="12">
                                     <label for="">
                                        备注
                                        <el-input v-model="input" placeholder="请输入内容" size="small"></el-input>
                                    </label>
                                 </el-col>
                             </el-row>
                        </div>
                    </div>
                    <div class="fabOrderItem">
                        <div class="fabItemTit">
                            保价服务
                        </div>
                        <div class="fabItemMain">
                            <label for="">
                                物品价值
                                <el-input v-model="input" placeholder="请填写物品声明价值(RMB)" size="small"></el-input>
                            </label>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                   <div class="fabOrderDetail">
                       <div class="orderDetailTit">
                           方案详情
                       </div>
                       <div class="orderDetailMain">
                            <el-row>
                                <el-col :span="8">
                                    <label for="">运输方案</label>
                                </el-col>
                                <el-col :span="16">
                                    <span>美国海派-美森-正班船(包税)</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <label for="">参考时效</label>
                                </el-col>
                                <el-col :span="16">
                                    <span>17-20工作日</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <label for="">计泡系数</label>
                                </el-col>
                                <el-col :span="16">
                                    <span>6000</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <label for="">方案保障</label>
                                </el-col>
                                <el-col :span="16">
                                    <span>延时赔</span>
                                    <span>损必赔</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <label for="">取件方式</label>
                                </el-col>
                                <el-col :span="16">
                                    <span>上门取件</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <label for="">取件时间</label>
                                </el-col>
                                <el-col :span="16">
                                    <span>2021-11-05 16:00-16:15</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <label for="">发货人</label>
                                </el-col>
                                <el-col :span="16">
                                    <span></span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <label for="">取件地址</label>
                                </el-col>
                                <el-col :span="16">
                                    <span>上海</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <label for="">亚马逊仓库</label>
                                </el-col>
                                <el-col :span="16">
                                    <span></span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <label for="">收货地址</label>
                                </el-col>
                                <el-col :span="16">
                                    <span>US</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <label for="">单价</label>
                                </el-col>
                                <el-col :span="16">
                                    <span>￥</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <label for="">计重费</label>
                                </el-col>
                                <el-col :span="16">
                                    <span>0.00KG</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <label for="">物流费</label>
                                </el-col>
                                <el-col :span="16">
                                    <span>￥0</span>
                                </el-col>
                            </el-row>
                            <el-row class="total">
                                <el-col :span="8">
                                    <label for="">订单总金额</label>
                                </el-col>
                                <el-col :span="16">
                                    <span>￥0.00</span>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-checkbox v-model="checked">已阅读《用户协议》并同意</el-checkbox>
                            </el-row>
                            <el-row class="btn">
                                <button type="button" class="subOrder">提交订单</button>
                            </el-row>
                       </div>
                   </div>
                </el-col>
            </el-row>
          </div>
      </div>
  </div>
</template>

<script>
    import FBAOrder from './FBAOrder.js';
    export default FBAOrder;
</script>

<style scoped>
    @import './FBAOrder.css';
</style>