import FBAOrder from './FBAOrder.vue'
export default {
    components: {
        FBAOrder
    },
    data() {
        return {
          ruleForm: {
              resource: '',
              date2: '',
              name: '',
              
          },
          formInline: {
              user: '',
              region: ''
          },
          options: [
            {
              value: 'ziyuan',
              label: '浙江',
              children: [{
                value: 'axure',
                label: '杭州'
              },]
            },
            {
              value: 'ziyuan',
              label: '上海',
              children: [{
                value: 'axure',
                label: '黄浦区'
              },
              {
                value: 'axure',
                label: '徐汇区'
                },
              {
                value: 'axure',
                label: '长宁区'
                },
                {
                  value: 'axure',
                  label: '静安区'
                },
                {
                  value: 'axure',
                  label: '普陀区'
                },]
          }],
          radio: '',
          checked: false
        }
    },
    methods: {
        
    }
}